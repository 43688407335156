













import vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class Background extends vue {}
